<template>
<div class="mainBox">
    <!--Header-->
    <div class="handleBox flex flex-jc-sb flex-ai-c">
        <el-page-header @back="goBack" :content="title"></el-page-header>
        <el-button type="primary" size="mini" block @click="submit">保存</el-button>
    </div>

    <!--form表单-->
    <div class="formBox shopBox">
        <!--商品信息-->
        <el-row style="height: calc(100%);overflow: auto;">
            <el-form ref="formAuth" :model="formData" label-width="120px" label-position="right" size="small" class="alertForm" :show-message="false" :status-icon="true">
                <el-form-item label="汽修厂名称" prop="name" required>
                    <el-input v-model="formData.name" placeholder="汽修厂名称" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="contacts" required>
                    <el-input v-model="formData.contacts" placeholder="联系人" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="mobile" required>
                    <el-input v-model="formData.mobile" placeholder="电话" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="生日" prop="birthday" required>
                    <el-date-picker v-model="formData.birthday" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="请选择"></el-date-picker>
                </el-form-item>
                <el-form-item label="销售站点" prop="site_id" required>
                    <el-select v-model="formData.site_id" @change="onSiteChange()" filterable placeholder="请选择" :disabled="isAdmin==false">
                        <el-option v-for="item in siteList" :key="item.id" :label="item.name" :value="item.id">
                            <span style="float: left">{{ item.name }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.tel }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="分类" prop="user_type_id" required>
                    <el-select v-model="formData.user_type_id" filterable placeholder="请选择">
                        <el-option v-for="item in typeList" :key="item.id" :label="item.type_name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="详细地址" prop="address" required style="width:100%">
                    <el-input placeholder="可点击下方地图选择，也可手动输入" @change="searchAddress()" v-model="formData.address"></el-input>
                </el-form-item>
                <div id="houseMap" ref="houseMap"></div>
            </el-form>
        </el-row>
    </div>
</div>
</template>
<script>
// import {
//     houseSaveApi,
//     houseReadApi,
//     houseGetAdminApi
// } from '@/api/house.js';
export default {
    data() {
        return {
            title: '客户', //标题
            formData: {}, //详情数据

            map: null,
            center: [],
            markerLayer: null,
            key: 'QZCBZ-VWOWX-ZKT47-TPVCA-KGGQ6-T2BOB',
            siteList: [],
            isAdmin: false,
            typeList: [],
        }
    },
    mounted() {
        if(this.$route.query.id != 'new') {
            this.getHouseDetail()
        }
        if(sessionStorage.getItem('bureau_id')) {
            this.isAdmin = false;
        } else {
            this.isAdmin = true;
        }
        this.initMap();
        this.getAdmin();
    },
    methods: {
        onSiteChange() {
            this.formData.user_type_id = '';
            this.getType()
        },
        getHouseDetail: function() {
            this.http.post('/admin.user/read', {
                id: this.$route.query.id
            }).then(re => {
                re.data.site_id = re.data.site_id == 0 ? '' : Number(re.data.site_id);
                this.formData = re.data;
                this.getType()
            })
        },
        //站点
        getAdmin: function() {
            this.http.post('/sales.site/getList', {
                curr: 1,
                row: 99999
            }).then(re => {
                this.siteList = re.data.list;
            })
        },
        getType() {
            this.http.post('/admin.UserType/index', { site_id: this.formData.site_id }).then(re => {
                this.typeList = re.data;
            })
        },
        // //初始化地图
        initMap: function() {
            let _t = this
            this.getLocation() //获取当前位置

            setTimeout(function() {
                //初始化地图
                _t.map = new window.TMap.Map(_t.$refs.houseMap, {
                    center: _t.center,
                    zoom: 14,
                    showControl: false,
                    mapStyleId: 'style1'
                });
                if(_t.formData.id) {
                    _t.markerLayer = new window.TMap.MultiMarker({
                        id: "marker-layer", //图层id
                        map: _t.map,
                        geometries: [{ //点标注数据数组
                            "position": new window.TMap.LatLng(_t.formData.lat, _t.formData.lng),
                        }]
                    });
                }


                //监听点击事件添加marker
                _t.map.on("click", (evt) => {

                    //若存在标记则清空
                    if(_t.markerLayer) {
                        _t.markerLayer.setMap(null);
                        _t.markerLayer = null;
                    }

                    //初始化marker图层
                    _t.markerLayer = new window.TMap.MultiMarker({
                        id: 'marker-layer',
                        map: _t.map
                    });

                    _t.markerLayer.add({
                        position: evt.latLng
                    });

                    _t.getAddress(evt.latLng.lat, evt.latLng.lng)
                });
            }, 500)
        },

        //获取当前位置经纬度
        getLocation: function() {
            this.$jsonp('https://apis.map.qq.com/ws/location/v1/ip', {
                key: this.key,
                output: 'jsonp',
            }).then(res => {
                if(res.status == '0') {
                    if(this.formData.id) {
                        this.center = new window.TMap.LatLng(this.formData.lat, this.formData.lng)
                    } else {
                        this.center = new window.TMap.LatLng(res.result.location.lat, res.result.location.lng)
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        },

        //获取点击地图时地址
        getAddress: function(lat, lng) {
            this.$jsonp('https://apis.map.qq.com/ws/geocoder/v1', {
                key: this.key,
                output: 'jsonp',
                location: lat + ',' + lng
            }).then(res => {
                if(res.status == '0') {
                    console.log(res.result);
                    this.formData.lat = parseFloat(res.result.location.lat)
                    this.formData.lng = parseFloat(res.result.location.lng)
                    this.formData.adcode = Number(res.result.ad_info.adcode)
                    this.formData.province = res.result.ad_info.province
                    this.formData.city = res.result.ad_info.city
                    this.formData.district = res.result.ad_info.district
                    this.$set(this.formData, 'address', res.result.address + res.result.formatted_addresses.recommend)
                }
            }).catch(err => {
                console.log(err)
            })
        },

        //搜索输入地图
        searchAddress: function() {
            let _this = this;
            //若标记存在则清空
            if(_this.markerLayer) {
                _this.markerLayer.setMap(null);
                _this.markerLayer = null;
            }

            this.$jsonp('https://apis.map.qq.com/ws/geocoder/v1', {
                key: this.key,
                output: 'jsonp',
                address: this.formData.address
            }).then(res => {
                console.log(res.result);
                if(res.status == '0') {
                    let position = new window.TMap.LatLng(res.result.location.lat, res.result.location.lng)

                    _this.formData.lat = parseFloat(res.result.location.lat)
                    _this.formData.lng = parseFloat(res.result.location.lng)
                    _this.formData.adcode = Number(res.result.ad_info.adcode)
                    _this.formData.province = res.result.address_components.province
                    _this.formData.city = res.result.address_components.city
                    _this.formData.district = res.result.address_components.district

                    _this.map.setCenter(position); //设置地图中心点

                    //设置标记
                    _this.markerLayer = new window.TMap.MultiMarker({
                        id: "marker-layer", //图层id
                        map: _this.map,
                        geometries: [{ //点标注数据数组
                            "position": position
                        }]
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: res.message
                    });
                }
            }).catch(() => {})
        },
        //保存
        submit: function() {
            this.$refs['formAuth'].validate((valid) => {
                if(valid) {
                    this.http.post('/admin.user/edit', this.formData).then(() => {
                        this.goBack();
                        this.$message({
                            type: 'success',
                            message: '保存成功!'
                        });
                    })
                }
            })
        },
        //返回上一页
        goBack() {
            this.$router.go(-1)
        },
    },
}
</script>
<style media="screen">
.mainBox {
    height: calc(100% - 20px);
    margin: 20px;
    margin-top: 0;
}

.shopBox {
    padding: 20px;
    box-sizing: border-box;
    height: calc(100% - 60px);
    background: #fff;
}

.el-form {
    height: 100%;
    margin-right: 0;
}

#houseMap {
    width: 100%;
    height: calc(100vh - 400px)
}
</style>
